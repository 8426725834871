input.input-phone {
  border-width: 0 0 2px 0;
  border-bottom: 2px solid #CACACA;
  padding: 0 0 8px;
  margin-top: 10px
}

input.input-phone:focus {
  border-bottom: 2px solid #00800A;
  outline: none!important;
}

.swal2-container {
  z-index: 10000!important;
}